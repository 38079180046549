/**
 * Settings 
 * 
 */

const settings = exports;

settings.version = "1.6.0";

settings.feedbackUrl = "https://docusign.co1.qualtrics.com/jfe/form/SV_55Nb4Kc8OplNYbP";

// See batch/codeExamplesSDKver.js
settings.sdkVersions = {
    csharp: "5.7.0",
    java: "3.15.0",
    nodejs: "^5.12.0",
    php: "v*",
    python: ">=3.12.0",
};
// Ruby always pulls the current version of the eSign gem

// The files where the string SDK_VERSION should be substituted from the sdkVersions setting
// If there's a second nodejs example, it could be "lang" nodejs2.
settings.sdkVersionModelFiles = {
    csharp: "src/assets/sdkExamples/models/CSharp_example.csproj",
    java:   "src/assets/sdkExamples/models/pom.xml",
    nodejs: "src/assets/sdkExamples/models/package.json",
    php:    "src/assets/sdkExamples/models/composer.json",
    python: "src/assets/sdkExamples/models/Pipfile",
}

settings.sdkVersionFiles = {
    csharp: "src/assets/sdkExamples/CSharp_example/CSharp_example/CSharp_example.csproj",
    java: "src/assets/sdkExamples/Java_example/pom.xml",
    nodejs: "src/assets/sdkExamples/NodeJS_example/package.json",
    php: "src/assets/sdkExamples/PHP_example/composer.json",
    python: "src/assets/sdkExamples/Python_example/Pipfile",
}

settings.jsonStart = 
{
    "envelopeDefinition": {
      "emailSubject": "Please sign the attached document",
      "status": "sent",
      "documents": [
        {
          "filename": "anchorfields.pdf",
          "name": "Example document",
          "fileExtension": "pdf",
          "documentId": "1"
        }
      ],
      "recipients": {
        "signers": [
          {
            "email": "signer_email@example.com",
            "name": "Signer's name",
            "recipientId": "1",
            "clientUserId": "1000",
            "tabs": {
              "signHereTabs": [
                {
                  "anchorString": "/sig1/",
                  "anchorXOffset": "20",
                  "anchorUnits": "pixels"
                }
              ]
            }
          }
        ]
      }
    },
    "envelopesCreateQP": {},
    "createRecipientViewReq": {
      "returnUrl": "https://docusign.com",
      "authenticationMethod": "None",
      "clientUserId": "1000",
      "email": "signer_email@example.com",
      "userName": "Signer's name"
    }
  };
